(function($) {





// Variables
// -----------------------------------------------------------------------------	

var tabletBreakpoint = 688;





// If Mobile
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		if(isMobile == true){
			$("html").removeClass("desktop-device");
			$("html").addClass("touch-device");
		}else{};
	});





// Lazy Load
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		lazyLoadInstance = new LazyLoad({
			elements_selector: ".lazy",
			threshold: 300,
		});
	});





// Scroll to Top of the Page
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		$(".scroll-to-top").click(function(event) {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, 1000);	
		});
	});

	
	
	

// Scroll to Next Section
// -----------------------------------------------------------------------------		
	
	$(document).ready(function(){
		$('.scroll-to-next').on('click', function(event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: $($(this).parents(".top-section").next()).offset().top,
	    		}, 750);
		});
	});
	
	
	

	
// Toggle Menu
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		$(".menu-toggle, .menu-close").click(function(event){
			event.preventDefault();
			$("html").toggleClass("menu-is-open");
		});
	});	





// Sticky Stickers
// -----------------------------------------------------------------------------	

	function stickyStickers(){
		var windowTop = $(window).scrollTop();
		var h5Height = $(".h5-wrapper").height();
		$("section").each(function(){
			var thisTop = $(this).offset().top;
			var thisBottom = thisTop + $(this).outerHeight();
			if(thisTop < windowTop && thisBottom > (windowTop + h5Height)){
				$(this).removeClass("is-past");
				$(this).addClass("is-active");
			}else if(thisBottom <= (windowTop + h5Height)){
				$(this).removeClass("is-active");
				$(this).addClass("is-past");
			}else{
				$(this).removeClass("is-active is-past");
			};
		});
	}
	
	$(document).ready(function(){
		stickyStickers();
		$(window).scroll(function(){
			stickyStickers();
		});
	});





// Top Section Height on Mobile Devices
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		if($(window).width() < tabletBreakpoint && $("body").hasClass("home")){
			var windowHeight = window.innerHeight;
			var headerHeight = $("#site-header").outerHeight();
			var captionHeight = $(".container.container--home").outerHeight();
			$(".top-section").css("height", (windowHeight - headerHeight));
			$(".top-section img").css("padding-bottom", captionHeight);
		}
	});	





// Hide Footer Until Scrolled Past
// -----------------------------------------------------------------------------	

	function revealFooter(){
		var windowTop = $(window).scrollTop();
		var windowHeight = window.innerHeight;
		var mainHeight = $("#site-main").outerHeight();
		var mainTop = $("#site-main").offset().top;
		if((windowTop + windowHeight) >= (mainHeight + mainTop)){
			$("#site-footer").addClass("is-visible");
		}else{
			$("#site-footer").removeClass("is-visible");
		};
	}
	
	$(document).ready(function(){
		revealFooter();
		$(window).scroll(function(){
			revealFooter();
		});
	});



// Fancybox
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		$('[data-fancybox="artworks"]').fancybox({
			toolbar: true,
			buttons: [
				"close"
			],
			transitionEffect: "slide",
			transitionDuration: 500,
			animationEffect: "fade",
			animationDuration: 400,
			wheel: false,
			idleTime: 0,
			loop : true,
			mobile: {
				clickContent: function(current, event){
					return false;
				},
			},
			baseTpl:
			    '<div class="fancybox-container" role="dialog" tabindex="-1">' +
			    '<div class="fancybox-bg"></div>' +
			    '<div class="fancybox-inner">' +
			    '<div class="fancybox-toolbar">{{buttons}}</div>' +
			    '<div class="fancybox-navigation">{{arrows}}</div>' +
			    '<div class="fancybox-stage"></div>' +
			    '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
			    '<div class="fancybox-caption-toggle"><p><a href="#" class="underlined-link js-toggle-caption">Show artwork details</a></p></div>' +
			    '</div>' +
			    '</div>',
			btnTpl: {
				close:
					'<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
					'&times;' +
					"</button>",
				arrowLeft:
					'<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
					'&uarr;' +
					"</button>",
				arrowRight:
					'<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
					'&darr;' +
					"</button>",
			},
			caption : function( instance, item ) {
				if ( item.type === 'image' ) {
					var caption = $(this).parent().find("figcaption").html();
				}
				return caption;
			},    
		});
		
		$(document).on('click', '.js-toggle-caption', function(event){
			event.preventDefault();
			$(".fancybox-caption").toggleClass("is-visible");
			if($(".fancybox-caption").hasClass("is-visible")){
				$(this).text("Hide artwork details");
			}else{
				$(this).text("Show artwork details");
			};
		});
	});
	
	
	
	
	
// Show More Text
// -----------------------------------------------------------------------------	
		
	$(document).ready(function(){
		
		if($(".truncate-text").length && $(".truncate-text").children().length > 3){
			$(".truncate-text").append('<a class="underlined-link truncateToggle margin-top-2" href="javascript:void(0);">Show more</a>');
			$(".truncate-text").children("p").not(':nth-child(-n+3)').wrapAll('<div class="truncated-text" style="display: none;" />');
			revealFooter();
			stickyStickers();
		};
		
		$(document).on('click', '.truncateToggle', function(){
			if($(".truncated-text:hidden").length){
				$(".truncateToggle").text("Show less");
				$(".truncated-text").slideToggle(400, stickyStickers());
			}else{
				$(".truncateToggle").text("Show more");
				$(".truncated-text").slideToggle(400, stickyStickers());
			}
		});
	});	





// Object Fit Polyfill
// -----------------------------------------------------------------------------	

	$(document).ready(function(){
		objectFitImages();
	});



})(jQuery);